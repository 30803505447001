import { cloneDeep } from 'lodash';
import { darken, lighten } from '@mui/system';
import { createTheme, alpha } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles';

import { WORKSPACE_SETTINGS_COLOR_KEYS } from 'constants/workspaceSettings';
import { rgbToHex } from 'helpers/colors';
import { AppColorsSettingType } from '../types';

import typography from './typography';

declare module 'tss-react/mui' {
  type DefaultTheme = Theme;
}

export type Property = string | number;
export type PaletteType = typeof defaultPalette;
export type GetWorkspacePaletteArgumentsType = {
  appColorsSetting: AppColorsSettingType;
};
export type GetMixinsType = { palette: PaletteType };
export type GetComponentsType = { palette: PaletteType };
export type GetWorkspaceThemeArgumentsType = {
  appColorsSetting: AppColorsSettingType;
};
type CustomTheme = {
  [Key in keyof typeof theme]: typeof theme[Key]
};
export type BatonTheme = CustomTheme & Theme;

export const PRIMARY_DARK_COLOR_DARKNESS_COEFFICIENT = 0.3 as const;
export const PRIMARY_LIGHT_COLOR_BRIGHTNESS_COEFFICIENT = 0.65 as const;

export const defaultPalette = {
  primary: {
    // light: will be calculated from palette.primary.main,
    // Baton purple
    main: '#1FBCFF',
    dark: '#2876BC',
    light: '#CEEFFF',
    // dark: OVERRIDING WITH #4840BB - thus it will not be calculated from palette.primary.main,
    // contrastText: will be calculated to contrast with palette.primary.main
  },
  secondary: {
    // light: will be calculated from palette.secondary.main,
    main: '#00b9d1',
    // dark: will be calculated from palette.secondary.main,
    // contrastText: will be calculated to contrast with palette.secondary.main
  },
  error: {
    // light: will be calculated from palette.error.main,
    main: '#FF3C64',
    // dark: will be calculated from palette.error.main,
    // contrastText: will be calculated to contrast with palette.error.main
  },
  alert: {
    high: '#FF3C64',
    med: '#FFC400',
    low: '#6FCF97',
    none: '#8F9194',
  },
  status: {
    grey: [
      '#8F9194',
      '#A5A7A9',
      '#C0C1C3',
      '#CECFD0',
      '#E4E5E5',
      '#EEEFEF',
      '#F2F2F2',
    ],
    green: [
      '#6FCF97',
      '#8CD9AC',
      '#AEE4C5',
      '#BAE8CD',
      '#DCF3E6',
      '#E9F8EF',
    ],
    yellow: [
      '#FFC400',
      '#FFD033',
      '#FFDE70',
      '#FFE385',
      '#FFF1C2',
      '#FFF6D9',
    ],
    red: [
      '#FF3C64',
      '#FF6383',
      '#FF92A8',
      '#FFA1B5',
      '#FFD0DA',
      '#FFE2E8',
    ],
    blue: [
      '#1FBCFF',
      '#63CDFF',
      '#85D8FF',
      '#A0E1FF',
      '#CEEFFF',
      '#E9F8FF',
    ],
    orange: [
      '#FF7D00',
      '#FF942C',
      '#FFAB5A',
      '#FFC48A',
      '#FFDBB7',
      '#FFF1E3',
    ],
    purple: [
      '#4840BB',
      '#6D66C9',
      '#9994D9',
      '#A7A3DE',
      '#D3D1EF',
      '#E4E2F5',
    ],
  },
  background: {
    default: '#FFFFFF',
    secondary: '#F5F7F7',
    dark: '#F6F6F6',
    active: '#F6F7FF',
    phase: '#EEEEEE',
    minty: '#BAE8CD',
  },
  border: {
    main: '#E2E2E2',
    secondary: '#D5D6DA',
    tertiary: '#DADADA',
    gutter: '#E5E5E5',
    activity: '#F5F5F5',
    hover: '#999A9E',
    late: '#F2A6B5',
  },
  button: {
    primary: {
      background: '#FF7D00',
      text: '#FFF',
    },
    roundIcon: {
      active: {
        background: '#2D21DA',
      },
    },
    outline: {
      background: '#FFF',
    },
    neutral: {
      background: '#252526',
      text: '#FFF',

      hover: {
        background: '#616164',
      },
    },
    neutralOutline: {
      background: '#FFF',
    },
    positive: {
      background: '#30A060',
      text: '#FFF',

      hover: {
        background: '#36A263',
      },
    },
    negative: {
      text: '#FFF',

      hover: {
        background: '#FFA2B5',
      },
    },
    negativeOutline: {
      background: '#FFF',
    },
    csOrange: {
      text: '#FFF',

      hover: {
        background: '#EE5A30',
      },
    },
    csDark: {
      text: '#FFF',

      hover: {
        background: '#2876BC',
      },
    },
  },
  brand1: '#4D65FF',
  brand2: '#3F24ED',
  brand3: '#6699FF',
  brand4: '#7C4DFF',
  icons: {
    dark: '#333333',
    gray: '#808080',
    gray2: '#D9D9D9',
    gray3: '#C0C1C3',
  },
  input: {
    background: '#FFF',
  },
  topBar: {
    color: '#666666',
    background: '#FFFFFF',
  },
  nav: {
    hover: '#F5F5F5',
    select2: 'rgba(77, 101, 255, 0.2);',
  },
  others: {
    dividers: '#2D2D2D',
    formField: '#F5F8F8',
    texture: '#F8FBFB',
  },
  template: '#C1D5FF',
  text: {
    primary: '#666666',
    secondary: '#7F7F7F',
    disabled: '#BABABA',
    tretiary: '#0090CD',
    placeholder: alpha('#4F4F4F', 0.8),
    // hint: alpha('#4F4F4F', 0.36),
  },
  avatars: [
    '#4527A0',
    '#7C4DFF',
    '#CE93D8',
    '#00695C',
    '#6EC0A4',
    '#A5D6A7',
    '#E65100',
    '#FF9100',
    '#FFB291',
  ],
  confettiColors: [
    '#1FBCFF',
    '#85D8FF',
    // based on the at risk yellow gradient used in project info header
    '#FF7D00',
    '#FFAB5A',
  ],
};

export const getWorkspacePalette = ({
  appColorsSetting,
}: GetWorkspacePaletteArgumentsType): PaletteType => {
  const {
    [WORKSPACE_SETTINGS_COLOR_KEYS.ACTION_BUTTON_BACKGROUND]: actionButtonBackgroundColor,
    [WORKSPACE_SETTINGS_COLOR_KEYS.ACTION_BUTTON_TEXT]: actionButtonTextColor,
    [WORKSPACE_SETTINGS_COLOR_KEYS.PRIMARY_MAIN]: primaryMainColor,
    [WORKSPACE_SETTINGS_COLOR_KEYS.TOP_BAR_BACKGROUND]: topBarBackgroundColor,
    [WORKSPACE_SETTINGS_COLOR_KEYS.TOP_BAR_TEXT]: topBarTextColor,
  } = appColorsSetting;

  const basePalette = cloneDeep(defaultPalette);

  if (actionButtonBackgroundColor) {
    basePalette.button.primary.background = actionButtonBackgroundColor;
  }
  if (actionButtonTextColor) {
    basePalette.button.primary.text = actionButtonTextColor;
  }
  if (primaryMainColor && basePalette.primary.main !== primaryMainColor) {
    basePalette.primary.dark = rgbToHex(darken(primaryMainColor, PRIMARY_DARK_COLOR_DARKNESS_COEFFICIENT));
    basePalette.primary.main = primaryMainColor;
    basePalette.primary.light = rgbToHex(lighten(primaryMainColor, PRIMARY_LIGHT_COLOR_BRIGHTNESS_COEFFICIENT));
  }
  if (topBarBackgroundColor) {
    basePalette.topBar.background = topBarBackgroundColor;
  }
  if (topBarTextColor) {
    basePalette.topBar.color = topBarTextColor;
  }

  return basePalette;
};

const getMixins = ({ palette }: GetMixinsType) => ({
  // eslint-disable-next-line default-param-last, @typescript-eslint/default-param-last
  position: (position: Property, top: Property = 'auto', right: Property, bottom: Property, left: Property) => {
    /* eslint-disable no-param-reassign */
    right = (right || right === 0) ? right : top;
    bottom = (bottom || bottom === 0) ? bottom : top;
    left = (left || left === 0) ? left : right;
    /* eslint-enable no-param-reassign */

    return { position, top, right, bottom, left };
  },
  flex: (alignItems: Property, justifyContent: Property, flexWrap = 'nowrap') => ({
    display: 'flex',
    alignItems,
    justifyContent: justifyContent || alignItems,
    flexWrap,
  }),
  flexCol: (alignItems: Property, justifyContent: Property) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: alignItems || 'stretch',
    justifyContent: justifyContent || alignItems || 'flex-start',
  }),
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  hideText: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: 1,
  },
  horizontalButtonPanel: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: 8,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  blink: {
    '@keyframes blink': {
      '0%, 100%': { opacity: 0 },
      '50%': { opacity: 1 },
    },
  },
  linkifyText: {
    color: palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      textDecoration: 'underline',
    },
  },
  normalizeList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
});

const sizeConstants = {
  globalNavCollapsedWidth: 48,
  globalNavExpandedWidth: 286,
  contentDrawerWidth: 480,
  appBarHeight: 64,
  helpButtonPadding: 70,
  navHeaderHeight: 60,
  projectDataHeaderHeight: 52,
  projectActionHeaderHeight: 52,
  projectCardWidth: 240,
  projectCardHeight: 100,
  projectColumnHeader: 60,
  checklistItemHeight: 37.625,
  sortableItemHeight: 44,
  sortableInputHeight: 45.5,
  projectTableLeftPadding: 48,
  taskHeight: 50.5,
  contentDrawerPadding: 16,
  taskRowMaxWidth: 1200,
  milestoneRowHeight: 48,
  projectBodyMilestoneDropZoneHeight: 97,
  projectOutlineMilestoneHeight: 135,
  projectOutlineMilestoneNoTasksHeight: 103,
  projectOutlineMilestoneDropZoneHeight: 72,
  startingLineHeaderHeight: 52,
  resourcesHeaderHeight: 48,
  resourcesNavWidth: 240,
  sideNavWidth: 240,
  draftHeaderHeight: 56,
};

const scrollbars = `
  *::-webkit-scrollbar {
    width: 16px;
    background-color: transparent;
  }

  *::-webkit-scrollbar-track {
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 16px;
    border: 4px solid transparent;
  }

  *:hover::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb:active {
    background-color: #babac0;
    border: 4px solid #fff;
  }

  *::-webkit-scrollbar-button {
    display: none;
  }
`;

const getComponents = ({ palette }: GetComponentsType) => ({
  MuiCssBaseline: {
    styleOverrides: `
      a {
        color: ${palette.text.primary};
      }

      ${window.navigator.userAgent.includes('Win') ? scrollbars : ''},
    `,
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiPaper: {
    defaultProps: {
      square: true,
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        ...typography.body1,
        padding: '4px 32px 4px 16px',
        minHeight: '36px !important',
        minWidth: 172,
        '& svg': {
          color: palette.icons.gray,
          marginRight: 8,
        },
        '&.Mui-selected': {
          background: alpha(palette.brand3, 0.2),
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        '&::-webkit-input-placeholder': {
          color: palette.text.placeholder,
          opacity: 1,
        },
        '&::moz-placeholder': {
          color: palette.text.placeholder,
          opacity: 1,
        },
        '&::-ms-input-placeholder': {
          color: palette.text.placeholder,
          opacity: 1,
        },
        '&::placeholder': {
          color: palette.text.placeholder,
          opacity: 1,
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        color: palette.text.primary,
      },
      indicator: {
        background: palette.primary.main,
        height: 3,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'capitalize',
        fontWeight: 500,
        minWidth: 'auto !important',
        height: sizeConstants.appBarHeight,
        '&.Mui-selected': {
          color: palette.primary.main,
        },
      },
      textColorInherit: {
        opacity: 1,
        color: palette.text.primary,
      },
    },
  },
});

const zIndexConstants = {
  timelineDependencies: 1,
  timelineButtons: 2,
  timelineArrows: 3,
  timelineFooter: 1098,
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};

export const theme = {
  breakpoints,
  palette: defaultPalette,
  typography,
  baseUnit: 4,
  spacing: 4,
  components: getComponents({ palette: defaultPalette }),
  mixins: getMixins({ palette: defaultPalette }),
  sizeConstants,
  zIndexConstants,
  transitions: {
    duration: {
      enteringScreen: 100,
      leavingScreen: 85,
    },
  },
} as const;

export const getWorkspaceTheme = ({ appColorsSetting }: GetWorkspaceThemeArgumentsType) => {
  const workspacePalette = getWorkspacePalette({ appColorsSetting });
  const components = getComponents({ palette: workspacePalette });
  const mixins = getMixins({ palette: workspacePalette });

  const workspaceTheme = {
    ...theme,
    palette: workspacePalette,
    components,
    mixins,
  };

  return createTheme(workspaceTheme);
};

export default createTheme(theme);
